import cn from 'classnames';
import React from 'react';
import { useParams, Navigate } from 'react-router-dom';

import { selectChartOfTheYear } from 'src/store/chart-of-the-year/selectors';
import { useAppSelector } from 'src/hooks/store';
import { routes } from 'src/constants';

import css from './CategoryWinner.module.scss';
import Footer from '../Footer';

interface Props {
  className?: string;
}

export const CategoryWinner: React.FC<Props> = (props) => {
  const chart = useAppSelector(selectChartOfTheYear);
  const { className = '' } = props;
  const [isMobile, setIsMobile] = React.useState(false);
  const { year, categorySlug } = useParams();
  const winnerData = chart.winners?.find((winner) => winner.slug === categorySlug);

  React.useEffect(() => {
    const controller = new AbortController();
    const onResize = () => {
      setIsMobile(window.innerWidth <= 799);
    };
    onResize();

    window.addEventListener('resize', onResize, { signal: controller.signal });

    return () => controller.abort();
  }, []);

  if (!winnerData) return <Navigate to={routes.ANNUAL_CHART.replace(':year', year!)} replace />;

  return (
    <div className={cn(css.categoryWinner, className)}>
      <div className={css.categoryWinnerWrapper}>
        <div className={css.coverImage}>
          <img src={isMobile ? winnerData.categoryImageMobile : winnerData.photo} alt={winnerData.name} />
        </div>
        <div className={css.info}>
          <div className={css.titleImage}>
            <img src={winnerData.categoryImageDesktop} alt={winnerData.name} />
          </div>
          <div className={css.description}>{winnerData.description}</div>
        </div>
      </div>
      <Footer isOnCategory />
    </div>
  );
};
